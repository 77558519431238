@import '~antd/dist/antd.less';
@import '~primeflex/primeflex.css';

.-mb-2 {
    margin-bottom: -0.5rem;
}

.app-32co {
    .ant-tabs-nav::before {
        border-bottom: none;
    }

    .ant-tabs-nav-wrap {
        border-bottom: 1px solid #ddd
    }

    .treatment-details-body {
        height: calc(100vh - 130px);
        overflow-y: auto;
    }

    .border-color {
        border-color: #ddd !important;
    }

    .ant-form-vertical .ant-form-item-label,
    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
        // padding: 0px;
    }

    .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none;
    }

    .ant-form-item {
        margin: 0px;
    }

    .ant-picker,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-number,
    .ant-input-affix-wrapper {
        background-color: #F9F9FA !important;
        border: 1px solid #F9F9FA;
        color: #686C80 !important;
    }

    .ant-input-password input {
        background-color: transparent !important;
    }

    .ant-form-item-has-error .ant-input-number, .ant-form-item-has-error .ant-picker {
        border-color: #ff4d4f;
    }

    .ant-input {
        background-color: #F9F9FA;
        border: 1px solid #F9F9FA;
        color: #686C80 !important;
    }

    .remove-error-explain-height {
        .ant-form-item-explain {
            min-height: 0px;
        }
    }

    .collapse-btn {
        .anticon.anticon-down {
            margin-left: 0px;
            line-height: 0;
        }
    }

    // button {
    //     min-width: 150px;
    // }

    .status-container {
        width: 150px;
        background: #ddd;
        border-radius: 20px;
        padding: 6px;
        font-size: 10px;
    }

    .card-padding-15 {
        .ant-card-body {
            padding: 15px;
        }
    }
}

.ant-card,
.custom-accordion {
    border-radius: 5px !important;
}

.text-danger {
    color: red;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: transparent;
}

// do not remove or move above
.ant-select-dropdown,
.ant-select-dropdown-empty {
    z-index: 99999;
}

.ant-steps-item-finish {
    .ant-steps-item-icon {
        background-color: @success-color;
        border-color: @success-color;

        .ant-steps-icon {
            color: #fff;
        }
    }

    .ant-steps-item-container>.ant-steps-item-tail::after {
        background-color: @success-color;
    }
}

.ant-steps-item-wait {
    .ant-steps-item-icon {
        border-color: @primary-color;

        .ant-steps-icon {
            color: @primary-color;
        }
    }
}

.sticky {
    z-index: 100;
    background: #fff;
    // padding-top: 16px !important;
}

.gray-color {
    color: #6B7D8B !important;
}

// Ant override css
.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after,
.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: var(--primary-color);
}

.light-primary-bg {
    background-color: @draft-btn-bg-color;
}

.radio-btn {
    border: 1px solid;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    margin-right: 10px;

    .radio-btn-dot {
        border: 1px solid #000;
        width: 10px;
        height: 10px;
        border-radius: 25px;
        background-color: #000;
        margin: auto;
        margin-top: 4px;
    }
}

.primary-background-color {
    background-color: @primary-background-color;
}

.secondary-background-color {
    background-color: @btn-selected-background-color;
}

.font-green {
    color: @success-color;
}

.ant-tooltip-inner {
    a {
        color: white;
    }
}

.ant-upload.ant-upload-drag {
    background: @primary-background-color;
    padding: 10px 5px;
    height: auto;
}

.ant-layout {
    background: #f7f7fa;
}

.custom-shadow {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05)
}

.upload-text {
    font-size: 12px;
}

.ant-tabs-tab .ant-tabs-tab-btn {
    color: #979797;
    font-size: 14px;
}

.ant-tabs-ink-bar {
    height: 2px;
    background: #09263D !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 500;
    color: #09263D;
}
@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00C878;