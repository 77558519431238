.signup-page {
    .signup-right-container {
        margin: 30px;
        margin-bottom: 0px;
        .have-account-container {
            span {
                font-size: 16px;
                &.sign-in-text {
                    font-weight: 600;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
        .signup-form-container {
            .signup-text {
                font-size: 24px;
                color: black;
                font-family: @font-family;
                letter-spacing: 0.5;
                margin: 0;
                font-weight: 600;
            }
            .ant-steps {
                display: none;
            }
            .steps-content {
                min-height: calc(100vh - 200px);
                margin-top: 16px;
                .ant-form-item {
                    margin-bottom: 10px;
                }
                .ant-form-item-label {
                    padding: 0;
                    .ant-form-item-required {
                        &::before {
                            content: none;
                        }
                    }
                }
                .add-another-btn-container {
                    display: flex;
                    justify-content: flex-end;

                    .practises-text-container {
                        span {
                            font-size: 18px;
                            font-family: @font-family;
                            color: #000;
                        }
                    }   
                }
                .item-remove-container {
                    display: flex;
                    justify-content: space-between;

                    .practises-text-container {
                        span {
                            font-size: 18px;
                            font-family: @font-family;
                            color: #000;
                            font-weight: 600;
                        }
                    } 
                }
                .border-top {
                    border-top: 2px solid #ddd;
                }
              }
              .steps-action {
                margin-top: 24px;
                justify-content: flex-end;
                display: flex;
                button {
                    min-width: 170px;
                    &.previoud-btn {
                        margin: 0 8px;
                    }
                }
              }
            .ant-steps-icon {
                display: none;
            }
            .ant-steps-item-icon {
                border-width: 2px;
            }
            .ant-steps-item-finish .ant-steps-item-icon, .ant-steps-item-active .ant-steps-item-icon {
                border: none;
            }
            .ant-steps-item-tail {
                top: 7px;
                &::after {
                    height: 3px;
                }
            }
        }
    }
}
@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00C878;