.login-page {
    height: inherit;
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    .create-account-container {
        display: flex;
        justify-content: space-between;

        span {
            font-size: 16px;
            color: #999;
        }

        a {
            span {
                font-size: 16px;
                color: #000;
                // font-weight: 600;
                border-bottom: 1px solid #000;
                cursor: pointer;
                // text-decoration: underline;
                padding-bottom: 2px;
            }
        }
    }
}
@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00C878;