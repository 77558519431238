.auth-layout {
    display: flex;
    width: 100vw;
    .left-container {
        position: fixed;
        width: 45vw;
        background-color: #000;
        height: 100vh;

        .why-32s-text {
            font-size: 28px;
            color: white;
            font-family: @font-family;
            letter-spacing: 0.5;
            margin: 0;
            font-weight: 600;
        }

        .logo-container {
            margin-bottom: 100px;
        }

        .signup-content-text {
            color: #999;
            font-size: 18px;
            font-family: @font-family;
            letter-spacing: 0.5;
            display: block;
            font-weight: 100;
        }

        .radio-box {
            width: 10px;
            height: 10px;
            background: #ddd;
            border-radius: 10px;
            margin-top: 7px;
            margin-right: 10px;
        }
    }

    .right-container {
        width: 55vw;
        margin-left: 45vw;
        height: 100vh;
    }
}

@media screen and (max-width: 768px) {
    .auth-layout {
        .left-container {
            display: none;
        }

        .right-container {
            margin-left: 0;
            width: 100vw;
        }
    }
}
@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00C878;