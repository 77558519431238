.layout {

    .sidebar {
        overflow: auto;
        height: 100vh;
        position: fixed;
        left: 0;
        background-color: #fff;
    }

    .site-layout {
        margin-left: 200px;

        .site-layout-background {
            background: #fff;
        }

        .app-header {
            width: calc(100% - 200px);
            // background-color: transparent;
            box-shadow: 3px 2px 3px 0px rgba(0, 0, 0, 0.1)
        }

        .content-section {
            margin-top: 80px;
            overflow: initial;
            min-height: calc(100vh - 80px);
        }

        &.side-nav-collapsed {
            margin-left: 80px;

            .app-header {
                width: calc(100vw - 80px);
            }
        }

        @media only screen and (max-width: 1200px)  {
            &.side-nav-collapsed {
                margin-left: 0px;
    
                .app-header {
                    width: 100%;
                }
            }
        }
    }
}



@font-family: Larsseit;@primary-color: #0060FF;@draft-btn-bg-color: #F5FBFF;@primary-background-color: #F5FBFF;@btn-selected-background-color: #09263D;@success-color: #00C878;