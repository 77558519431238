@font-face {
  font-family: "Larsseit";
  src: url("assets/fonts/Larsseit.otf");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Larsseit-Medium";
  src: url("assets/fonts/Larsseit-Medium.otf");
  font-style: normal;
  font-weight: 500;
}

:root {
  --border-radius: 4px;
  --gray-100: #f5f5f5;
  --gray-400: #ddd;
  --primary-color: #0060FF;
}

body {
  margin: 0;
  font-family: Larsseit, "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
